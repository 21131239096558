var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-table',{staticClass:"table table-head-custom table-head-bg table-borderless table-vertical-center",attrs:{"thead-class":"mb-8","show-empty":"","empty-text":"Listelenecek herhangi bir firma bulunamadı!","stacked":"lg","items":_vm.companies,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Yükleniyor...")])],1)]},proxy:true},{key:"cell(company)",fn:function(data){return [_c('div',{staticClass:"py-2 pl-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"},[_c('div',{staticClass:"symbol-label",staticStyle:{"background-size":"contain"},style:({
                backgroundImage: ("url(" + _vm.$MEDIA_PROD_ENV + "transport-company/logo/" + (data.item.company.logo) + ")")
              })})]),_c('div',[_c('span',{staticClass:"text-dark-75 font-weight-bolder font-size-lg mb-1"},[_vm._v(_vm._s(data.item.company.name))]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(_vm._s(data.item.company.commercialTitle))])])])])]}},{key:"cell(authorizedPerson)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"text-dark-75 font-weight-bolder font-size-lg mb-1"},[_vm._v(_vm._s(data.item.authorizedPerson.fullName))]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(_vm._s(data.item.authorizedPerson.phoneNumber))])])])]}},{key:"cell(actions)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'edit-transport-company',
          params: { companyId: data.item.id }
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light btn-hover-primary btn-sm",on:{"click":navigate}},[_vm._v(" Düzenle ")])]}}],null,true)})]}}])}),(_vm.isPaginationVisible)?_c('b-col',{staticClass:"my-1",attrs:{"sm":"7","md":"6"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"size":"md"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }